import actions from './actions'

const initialState = {
  smsListInfo: null,
  searchSms: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
}

export default function smsverifyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

import { createSelector } from 'reselect'

export const SelectsubscriptionRequest = state => state?.subrequest

export const SelectedSubscriptionsRequestData = createSelector(
  [SelectsubscriptionRequest],
  subrequest => {
    const { searchCustomer, requestInfo } = subrequest
    if (searchCustomer && requestInfo) {
      return requestInfo.filter(
        r =>
          r?.customer_name?.toLowerCase().includes(searchCustomer?.toLowerCase()) ||
          r?.planName?.toLowerCase()?.includes(searchCustomer?.toLowerCase()) ||
          r?.mobile_number?.includes(searchCustomer) ||
          r?.amount?.toString()?.includes(searchCustomer) ||
          r?.planNo?.toString() === searchCustomer,
      )
    }
    return requestInfo
  },
)

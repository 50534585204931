import React, { useState, useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import CustomerAction from 'redux/customers/actions'
import { createStructuredSelector } from 'reselect'
import { selectCustomers, selectedVideosdata } from 'redux/customers/selectors'
import { Input, Form, DatePicker, Badge, Table, Tag, Spin, Button } from 'antd'
import { getFormattedUTC, getFormattedUTCwithTime } from 'utils/parser'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    customers: selectCustomers,
    selectedVideos: selectedVideosdata,
  })

const Devicelist = ({ dispatch, selectedVideos, intl: { formatMessage } }) => {
  const onSearch = value => {
    dispatch({
      type: CustomerAction.SET_STATE,
      payload: { searchVideos: value },
    })
  }

  const [form] = Form.useForm()

  const [startTime, setstartTime] = useState()

  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  const VideosApidata = selectedVideos

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: CustomerAction.LOAD_VIDEO_LOGS,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const onClick = () => {
    form.resetFields()
    dispatch({
      type: CustomerAction.LOAD_VIDEO_LOGS,
      payload: userId,
    })
  }

  const onFinish = () => {
    dispatch({
      type: CustomerAction.GETVIDEOSFILTER,
      payload: {
        userIdVal: userId,
        from: startTime.format('YYYY-MM-DD'),
      },
    })
  }

  const onChange = (dates, dateStrings) => {
    console.log('dates')
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])

    if (dates) {
      setstartTime(dates)
    } else {
      console.log('Clear')
    }
  }

  const onViewStoreClick = values => {
    window.open(values)
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * pageSize + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.filename' }),
      dataIndex: 'filename',
      key: 'filename',
      render: record => (
        <>
          {record}
          {'  -  '}
          <button type="button" className="btn btn-light" onClick={() => onViewStoreClick(record)}>
            <i className="fe fe-play mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.viewStore' })}
          </button>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.source' }),
      render: (text, record) => (
        <>
          {record?.video_source === 'youtube' && (
            <>
              <Tag color="brown" style={{ textTransform: 'capitalize' }}>
                Youtube
              </Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.status' }),
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <>
          {text === true && (
            <>
              <Badge color="green" text={formatMessage({ id: 'text.active' })} />
            </>
          )}
          {text === false && (
            <>
              <Badge color="red" text={formatMessage({ id: 'text.inactive' })} />
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.watched_at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => (
        <>
          {getFormattedUTC(text)}
        </>
      ),
      // render: text => moment(text).format('MMM D, YYYY'),
    },
    {
      title: formatMessage({ id: 'text.watched_on' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => (
        <>
          {getFormattedUTCwithTime(text)}
        </>
      ),
      // render: text => moment(text).format('hh:mm:ss a'),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.devicelist' }),
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
        <div className="row">
          <div className="col-md-4">
            <Form.Item>
              <Search
                style={{ width: '100%' }}
                placeholder={formatMessage({ id: 'form.placeholder.videoLogs.search' })}
                size="large"
                onChange={e => onSearch(e.target.value)}
                allowClear
              />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form
              form={form}
              layout="vertical"
              hideRequiredMark
              // initialValues={{
              //   from: moment(leads?.leadfilterdata?.startDate),
              // }}
              onFinish={onFinish}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="taskDate">
                    <DatePicker format="YYYY-MM-DD" onChange={onChange} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="small">
                      {formatMessage({ id: 'action.filtersearch' })}
                    </Button>
                    &nbsp;&nbsp;
                    <Button type="primary" size="small" onClick={onClick}>
                      {formatMessage({ id: 'action.clear' })}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="kit__utils__table">
          <Suspense fallback={<Spin />}>
            {VideosApidata?.length >= 0 ? (
              <Table
                dataSource={VideosApidata}
                columns={columns}
                locale={locale}
                rowKey="_id"
                pagination={{
                  current: page,
                  pageSize1: pageSize,
                  total: VideosApidata?.length || 0,
                  onChange: (current, newPageSize) => {
                    setPageSize(newPageSize)
                    setPage(pageSize !== newPageSize ? 1 : current)
                  },
                }}
              />
            ) : (
              <Table
                columns={columns}
                locale={locale}
                loading={{
                  indicator: (
                    <div>
                      <Spin />
                    </div>
                  ),
                }}
              />
            )}
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Devicelist))

import React from 'react'
// import LanguageSwitcher from './LanguageSwitcher'
import { Avatar, Badge, Space } from 'antd'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import {
  SelectsubscriptionRequest,
  SelectedSubscriptionsRequestData,
} from 'redux/subrequest/selectors'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import UserMenu from './UserMenu'
import style from './style.module.scss'

const mapStateToProps = () =>
  createStructuredSelector({
    subscriptionRequest: SelectsubscriptionRequest,
    selectedSubscriptionRequest: SelectedSubscriptionsRequestData,
  })

const TopBar = ({ selectedSubscriptionRequest }) => {
  const datetime = new Date()
  const todayDate = moment(datetime).format('YYYY-MM-DD')

  const unSubscribed = selectedSubscriptionRequest?.filter(
    x => x.validated === false && x.status === false,
  )
  const unsubscribedCount = unSubscribed?.length || 0

  const expired = selectedSubscriptionRequest?.filter(x => x.expiry_date < todayDate)
  const expiredCount = expired?.length || 0

  return (
    <div className={style.topbar}>
      <div className="mr-4" />
      <div className="mr-auto" />
      <div className="mr-4 d-none d-md-block" />
      <div className="mb-0 mr-auto d-xl-block d-none" />
      {/* <div className="mr-4 d-none d-sm-block" style={{display:'none'}}>
        <LanguageSwitcher />
      </div> */}
      <Space>
        <Badge count={expiredCount}>
          Expired Subscription : <Avatar shape="square" size="small" />
        </Badge>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Badge count={unsubscribedCount}>
          Subscription : <Avatar shape="square" size="small" />
        </Badge>
      </Space>
      <div className="mr-4 d-none d-sm-block" />
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(TopBar))

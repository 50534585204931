import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import UserAction from 'redux/user/actions'
import { store as reduxStore } from 'index'

const apiClient = axios.create({
  baseURL: 'https://prod-api.llbs.co.in/',
  // baseURL: 'http://localhost:3001/',
  // baseURL: 'https://llbs-api.onrender.com/',
  headers: {
    common: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin',
    },
  },
})

apiClient.interceptors.request.use(request => {
  request.params = request.params || {}
  const accessToken = store.get('user.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  const userName = store.get('user.userName')
  if (userName) {
    request.params.userId = userName
  }
  if (accessToken) {
    request.params.accessToken = accessToken
  }

  return request
})

apiClient.interceptors.response.use(
  response => {
    if (response?.data?.status === 200) {
      const { message } = response.data
      notification.success({
        message,
      })
    } else if (response?.data?.status === 400) {
      const { message } = response.data
      notification.error({
        message,
      })
    } else if (response?.data?.status === 401) {
      const { message } = response.data
      notification.warning({
        message,
      })
    }
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      reduxStore.dispatch({
        type: UserAction.CLEAR_USER,
      })
    } else if (error?.response?.data != null) {
      const { message } = error.response.data
      notification.error({
        message,
      })
    }
    throw error
  },
)

// apiClient.interceptors.response.use(
//   response => {
//     console.log('res', response)
//     if (response?.data?.message != null) {
//       const { message } = response.data
//       notification.success({
//         message,
//       })
//     }
// return response
//   },
//   error => {
//     if (error?.response?.status === 401) {
//       reduxStore.dispatch({
//         type: UserAction.CLEAR_USER,
//       })
//     } else if (error?.response?.data != null) {
//       const { message } = error.response.data
//       notification.error({
//         message,
//       })
//     }
//     throw error
//   },
// )

export default apiClient

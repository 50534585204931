import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadAll, loadpayouthistory, getlateststatus, statuschange } from 'api/payouts'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { PayoutList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        payoutsInfo: PayoutList,
      },
    })
  }
}

export function* GET_PAYOUT_HISTORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: true,
    },
  })
  const { response } = yield call(loadpayouthistory, payload)
  const { payoutHistory } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
      displayPayoutHistoryDrawer: true,
      payoutHistoryRes: payoutHistory,
    },
  })
}

export function* GET_CURRENT_STATUS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: true,
    },
  })
  const { response } = yield call(getlateststatus, payload)
  const { payoutStatus } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
      currentStatus: payoutStatus,
    },
  })
}


export function* STATUS_CHANGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(statuschange, payload)
  if (response)
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        displayPayoutStatusModal: false,
      },
    })
  yield call(LOAD_ALL)

}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.GET_PAYOUT_HISTORY, GET_PAYOUT_HISTORY),
    takeLatest(actions.GET_CURRENT_STATUS, GET_CURRENT_STATUS),
    takeLatest(actions.STATUS_CHANGE, STATUS_CHANGE),
  ])
}

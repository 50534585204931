import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs, Select, Tag, Form } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { phoneNumberNational } from 'utils/parser'
import CustomerAction from 'redux/customers/actions'
import { history } from 'index'
import CustomerDetail from './CustomerDetail'
import BankDetail from './BankDetail'
import DeviceDetail from './DeviceDetail'
import VideoLogs from './VideoLogs'
import WalletHistory from './WalletHistory'



const { TabPane } = Tabs

const { Option } = Select


const mapStateToProps = ({ dispatch, customers, user }) => ({
  dispatch,
  customers,
  user,
})

const StoreInfo = ({ dispatch, customers, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')




  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: CustomerAction.LOAD_CUSTOMER_INFO,
        payload: userId,
      })
    return () => {
      dispatch({
        type: CustomerAction.SET_STATE,
        payload: {
          search: null,
        },
      })
    } // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: CustomerAction.LOAD_ALL,
    })
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)

  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'customer-info') {
    tabUrlVal = '1'
  } else if (tabname === 'device-info') {
    tabUrlVal = '2'
  } else if (tabname === 'video-logs') {
    tabUrlVal = '3'
  } else if (tabname === 'wallet-history') {
    tabUrlVal = '4'
  }
  else if (tabname === 'bank-info') {
    tabUrlVal = '5'
  }

  const phoneNumber = phoneNumberNational(customers?.detailInfo?.mobile_number)

  const [form] = Form.useForm()


  useEffect(() => {
    if (customers?.detailInfo) {
      form.setFieldsValue({
        customer_name: customers?.detailInfo?.first_name,
      })
    }
  }, [form, customers])

  const customerArray = customers?.customerInfo?.sort((a, b) => a?.first_name > b?.first_name ? 1 : -1)


  const onValuesChange = changedValues => {
    const arrayData = customerArray?.filter(item => item?.first_name === changedValues?.customer_name)
    const customerId = arrayData[0]?._id
    console.log("arrayData", customerId)
    const newPath = '/manage/customer-info/'
    const newPathInfo = newPath
    const pathInfo = generatePath(newPathInfo);
    history.replace(pathInfo)
    console.log("path", pathInfo)
  }






  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.customer',
        })}
      />
      <div className="row">
        <div className="col-md-8">
          <h5 className="mb-4">
            <strong><span style={{ textTransform: 'capitalize' }}>{`${customers?.detailInfo?.first_name} ${customers?.detailInfo?.last_name}`}</span> - {`${phoneNumber} - Android`}</strong>
            &nbsp;
            {customers?.detailInfo?.status === true && <Tag color="green">Active</Tag>}
            {customers?.detailInfo?.status === false && <Tag color="red">InActive</Tag>}
          </h5>
          {customers?.detailInfo?.email_verified === true && (
            <>
              <Tag color="green" style={{ textTransform: 'capitalize' }}>
                Email Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.email_verified !== true && (
            <>
              <Tag color="red" style={{ textTransform: 'capitalize' }}>
                Email Not Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.mobile_verified === true && (
            <>
              <Tag color="green" style={{ textTransform: 'capitalize' }}>
                Mobile Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.mobile_verified !== true && (
            <>
              <Tag color="red" style={{ textTransform: 'capitalize' }}>
                Mobile Not Verified
              </Tag>
            </>
          )}
        </div>
        <div className="col-md-4">
          <Form layout="vertical" hideRequiredMark onValuesChange={onValuesChange} form={form}>
            <Form.Item
              name="customer_name"
              rules={[
                { required: true, message: formatMessage({ id: 'form.error.required' }) },
              ]}
            >
              <Select
                showSearch
                placeholder={formatMessage({ id: 'form.placeholder.customer' })}
                size="large"
              >
                {customerArray?.map(o => (
                  <Option
                    key={o.first_name}
                    value={o.first_name}
                  >
                    {`${o.first_name} ${o.last_name}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=customer-info">
              <span>{formatMessage({ id: 'text.storeino.tab.customerInfo' })}</span>
            </Link>
          }
          key={1}
        >
          <CustomerDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=bank-info">
              <span>{formatMessage({ id: 'text.storeino.tab.BankDetail' })}</span>
            </Link>
          }
          key={5}
        >
          <BankDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=device-info">
              <span>{formatMessage({ id: 'text.storeino.tab.deviceInfo' })}</span>
            </Link>
          }
          key={2}
        >
          <DeviceDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=video-logs">
              <span>{formatMessage({ id: 'text.storeino.tab.VideoLogs' })}</span>
            </Link>
          }
          key={3}
        >
          <VideoLogs />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=wallet-history">
              <span>{formatMessage({ id: 'text.storeino.tab.WalletHistory' })}</span>
            </Link>
          }
          key={4}
        >
          <WalletHistory />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))

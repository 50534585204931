const actions = {
  SET_STATE: 'configurations/SET_STATE',

  LOAD_ALL: 'configurations/LOAD_ALL',
  LOAD_COMPANY_INFO: 'configurations/LOAD_COMPANY_INFO',
  UPDATE_SETTINGS: 'configurations/UPDATE_SETTINGS',
  UPDATE_COMPANY_SETTINGS: 'configurations/UPDATE_COMPANY_SETTINGS',
}

export default actions

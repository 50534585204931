export default async function getMenuData() {
  return [
    // {
    //   title: 'Manage Accounts',
    //   key: 'manage-accounts',
    //   icon: 'fe fe-file',
    //   url: '/manage/accounts',
    // },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Customers',
      key: 'manage-customers',
      icon: 'fe fe-user',
      url: '/manage/customers',
    },
    {
      title: 'Manage Subscriptions',
      key: 'manage-subscriptions',
      icon: 'fe fe-user',
      url: '/manage/subscriptions',
    },
    {
      title: 'Subscription Request',
      key: 'subscription-request',
      icon: 'fe fe-user',
      url: '/subscription-request',
    },
    {
      title: 'Manage Videos',
      key: 'videos',
      icon: 'fe fe-user',
      url: '/manage/videos',
    },
    {
      title: 'Manage Payouts',
      key: 'payouts',
      icon: 'fe fe-user',
      url: '/manage/payouts',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-user',
      url: '/settings',
    },
    {
      title: 'Company Info',
      key: 'company-info',
      icon: 'fe fe-user',
      url: '/company-info',
    },
    {
      title: 'Sms Verifications',
      key: 'smsverifications',
      icon: 'fe fe-user',
      url: '/smsverifications',
    },
  ]
}

import apiClient from 'services/axios'

const BASE_URL = 'api/videos'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/video-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadvideo(payload) {
  return apiClient
    .post(`${BASE_URL}/uploadvideo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(videoId) {
  return apiClient
    .put(`${BASE_URL}/video-delete-web/${videoId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCost(videoId, updatedData) {
  return apiClient
    .put(`${BASE_URL}/updatecost/${videoId}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateUrl(payload) {
  console.log('updateUrl', payload)
  const videoId = payload?._id
  delete payload?._id
  return apiClient
    .put(`${BASE_URL}/updateUrl/${videoId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Input, Form, Button, Switch, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import CustomerAction from 'redux/customers/actions'
// import MaskedInput from 'antd-mask-input'
// import { phoneNumberE164, phoneNumberNational } from 'utils/parser'
import Loading from 'components/app/common/Loading'
import CardTitle from 'components/app/common/CardTitle'
import moment from 'moment'

const mapStateToProps = ({ dispatch, customers, user }) => ({
  dispatch,
  customers,
  user,
})

const UpdateUser = ({ customers, intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  const onFinish = values => {
    delete values?.dob
    delete values?.pan_no
    delete values?.aadhaar_no
    // delete values?.pan_front_side_url
    // delete values?.aadhaar_front_side_url
    // delete values?.aadhaar_back_side_url
    // delete values?.profile_photo_url
    delete values?.address
    delete values?.landmark
    delete values?.city
    delete values?.state
    delete values?.country
    delete values?.pincode
    delete values?.is_foreign
    dispatch({
      type: CustomerAction.UPDATE_CUSTOMER_DETAIL,
      payload: {
        updatedData: values,
        UseridSel: userId,
      },
    })
  }

  const [form] = Form.useForm()

  const onChange1 = (date, dateString) => {
    console.log(date, dateString)
  }

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  useEffect(() => {
    const apiCallInfo = customers?.detailInfo
    if (apiCallInfo) {
      form.setFieldsValue({
        first_name: customers?.detailInfo?.first_name,
        last_name: customers?.detailInfo?.last_name,
        emailid: customers?.detailInfo?.emailid,
        status: customers?.detailInfo?.status,
        mobile_number: customers?.detailInfo?.mobile_number,
        dob: moment(customers?.personalInfo?.dob),
        pan_no: customers?.personalInfo?.pan_no,
        aadhaar_no: customers?.personalInfo?.aadhaar_no,
        address: customers?.locationInfo?.address,
        landmark: customers?.locationInfo?.landmark,
        city: customers?.locationInfo?.city,
        state: customers?.locationInfo?.state,
        country: customers?.locationInfo?.country,
        pincode: customers?.locationInfo?.pincode,
        is_foreign: customers?.locationInfo?.is_foreign,
        // profile_photo_url: customers?.personalInfo?.profile_photo_url,
        // mobile_number: phoneNumberNational(customers?.detailInfo?.mobile_number),
      })
    }
  }, [form, customers])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            form={form}
            initialValues={{
              first_name: customers?.detailInfo?.first_name,
              last_name: customers?.detailInfo?.last_name,
              emailid: customers?.detailInfo?.emailid,
              mobile_number: customers?.detailInfo?.mobile_number,
              dob: moment(customers?.personalInfo?.dob),
              pan_no: customers?.personalInfo?.pan_no,
              aadhaar_no: customers?.personalInfo?.aadhaar_no,
              address: customers?.locationInfo?.address,
              landmark: customers?.locationInfo?.landmark,
              city: customers?.locationInfo?.city,
              state: customers?.locationInfo?.state,
              country: customers?.locationInfo?.country,
              pincode: customers?.locationInfo?.pincode,
              is_foreign: customers?.locationInfo?.is_foreign,
              profile_photo_url: customers?.personalInfo?.profile_photo_url,
            }}
          >
            <CardTitle />
            <div className="row">
              <div className="col-12" hidden={customers?.detailInfo}>
                <Loading />
              </div>
            </div>
            <div className="row" hidden={!customers.detailInfo}>
              <div className="col-md-4">
                <Form.Item
                  name="first_name"
                  label={formatMessage({ id: 'form.label.first_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.first_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="last_name"
                  label={formatMessage({ id: 'form.label.last_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.last_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>

              <div className="col-md-4">
                <Form.Item
                  name="emailid"
                  label={formatMessage({ id: 'form.label.email' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.email.required' }) },
                    { type: 'email', message: formatMessage({ id: 'form.error.email.type' }) },
                    { max: 64, message: formatMessage({ id: 'form.error.email.max' }) },
                  ]}
                >
                  <Input
                    type="email"
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.email' })}
                    maxLength={64}
                    onInput={toInputLowercase}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.mobile_number' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile_number' })}
                    maxLength={64}
                  />
                </Form.Item>
                {/* <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item> */}
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="dob"
                  label={formatMessage({ id: 'form.label.dob' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker onChange={onChange1} style={{ width: '50%' }} disabled />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="pan_no"
                  label={formatMessage({ id: 'form.label.pan_no' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.pan_no' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="aadhaar_no"
                  label={formatMessage({ id: 'form.label.aadhaar_no' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.aadhaar_no' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>

              {/* <div className="col-md-4">
                <Form.Item
                  name="profile_photo_url"
                  label={formatMessage({ id: 'form.label.profile_photo_url' })}
                >
                  <Image width={100} src={customers?.personalInfo?.profile_photo_url} />
                </Form.Item>
              </div> */}
              {/* <div className="col-md-4">
                <Form.Item
                  name="pan_front_side_url"
                  label={formatMessage({ id: 'form.label.pan_front_side_url' })}
                >
                  <Image width={100} src={customers?.personalInfo?.pan_front_side_url} />
                </Form.Item>
              </div> */}

              {/* <div className="col-md-4">
                <Form.Item
                  name="aadhaar_front_side_url"
                  label={formatMessage({ id: 'form.label.aadhaar_front_side_url' })}
                >
                  <Image width={100} src={customers?.personalInfo?.aadhaar_front_side_url} />
                </Form.Item>
              </div> */}

              {/* <div className="col-md-4">
                <Form.Item
                  name="aadhaar_back_side_url"
                  label={formatMessage({ id: 'form.label.aadhaar_back_side_url' })}
                >
                  <Image width={100} src={customers?.personalInfo?.aadhaar_back_side_url} />
                </Form.Item>
              </div> */}
              <div className="col-md-4">
                <Form.Item
                  name="address"
                  label={formatMessage({ id: 'form.label.address' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.address' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="landmark"
                  label={formatMessage({ id: 'form.label.landmark' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.landmark' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="city"
                  label={formatMessage({ id: 'form.label.city' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.city' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="state"
                  label={formatMessage({ id: 'form.label.state' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.state' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="country"
                  label={formatMessage({ id: 'form.label.country' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.country' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="pincode"
                  label={formatMessage({ id: 'form.label.pincode' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.pincode' })}
                    maxLength={64}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="is_foreign"
                  label={formatMessage({ id: 'form.label.is_foreign' })}
                // rules={[
                //   { required: true, message: formatMessage({ id: 'form.error.required' }) },
                // ]}
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.NRI' })}
                    unCheckedChildren={formatMessage({ id: 'action.Indian' })}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="status"
                  label={formatMessage({ id: 'form.label.user_status' })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.active' })}
                    unCheckedChildren={formatMessage({ id: 'action.inactive' })}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit" size="large" loading={customers?.updating}>
                {formatMessage({ id: 'action.update' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(UpdateUser))

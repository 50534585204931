import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import customers from './customers/reducers'
import subscriptions from './subscriptions/reducers'
import subrequest from './subrequest/reducers'
import videos from './videos/reducers'
import payouts from './payouts/reducers'
import configurations from './configurations/reducers'
import smsverify from './smsverify/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    staff,
    customers,
    subscriptions,
    subrequest,
    videos,
    payouts,
    configurations,
    smsverify,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}

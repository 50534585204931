import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadAll,
  updateSettings,
  loadcompany,
  updateCompanyInfo
} from 'api/settings'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { configDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        configInfo: configDetail,
      },
    })
  }
}

export function* LOAD_COMPANY_INFO() {
  const { response } = yield call(loadcompany)
  if (response) {
    const { companyDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        companyInfo: companyDetail,
      },
    })
  }
}



export function* UPDATE_SETTINGS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { updatedData } = payload
  const { response } = yield call(updateSettings, updatedData)
  const { configDetail } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      configInfo: configDetail,
    },
  })
}

export function* UPDATE_COMPANY_SETTINGS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { updatedData } = payload
  const { response } = yield call(updateCompanyInfo, updatedData)
  const { companyDetail } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      companyInfo: companyDetail,
    },
  })
}




export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.UPDATE_SETTINGS, UPDATE_SETTINGS),
    takeLatest(actions.LOAD_COMPANY_INFO, LOAD_COMPANY_INFO),
    takeLatest(actions.UPDATE_COMPANY_SETTINGS, UPDATE_COMPANY_SETTINGS),
  ])
}